//----------------------------------------------------------//
//	MODAL
//----------------------------------------------------------//
.modal.fade.modal-bottom-center .modal-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
@media (min-width: 576px) {
    .modal.fade.modal-bottom-center .modal-dialog {
        margin: 1.75rem auto;
    }
}
@media (max-width: 767.98px) {
    .modal.fade.modal-bottom-center .modal-dialog {
        width: calc(100% - (0.5rem*2));
    }
}