//----------------------------------------------------------//
//	ALERTS
//----------------------------------------------------------//
.alert-dismissible {
  .btn-close {
    position: absolute !important;
    top: 0;
    right: 0;
    z-index: $stretched-link-z-index + 1;
    padding: $alert-padding-y * 1.05 $alert-padding-x;
    &:before {
      background: none;
      @include font-size(1.2rem);
      width: 1.2rem;
      height: 1.2rem;
    }
    &:hover {
      &:before {
        background: none;
      }
    }
  }
  @each $color,
  $value in $theme-colors {
    &.alert-#{$color} .btn-close {
      color: shift-color($value, $alert-color-scale - 20);
    }
    &.alert-#{$color} .btn-close:hover {
      color: shift-color($value, $alert-color-scale + 10);
    }
  }
}
.alert-icon {
  padding-left: $alert-padding-x * 2.5;
  // Adjust close link position
  i {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $stretched-link-z-index + 1;
    padding: 0.8rem $alert-padding-x;
    @include font-size(1rem);
  }
}
// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert.
@each $state,
$value in $theme-colors {
  $background: shift-color($value, $alert-bg-scale);
  $border: shift-color($value, $alert-border-scale);
  $color: shift-color($value, $alert-color-scale);
  .alert-#{$state} {
    @include alert-variant($background, $border, $color);
  }
}
.alert[class*="bg-"] {
  padding: 0.8rem 0; 
  .alert-inner {
    padding-right: 3rem;
  }
  &.alert-dismissible {
    .btn-close {
      padding-top: 0;
      padding-bottom: 0;
      top: 50%;
      transform: translateY(-50%);
      color: rgba($white, 0.8);
    }
    .btn-close:hover {
      color: $white;
    }
  }
}
@media (max-width: 767px) {
  .alert[class*="bg-"].alert-dismissible .btn-close {
    padding-right: 0.5rem;
  }
}