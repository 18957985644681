//----------------------------------------------------------//
//	POPOVER
//----------------------------------------------------------//
.has-popover:focus {
	outline: 0;
}
.popover-header {
	padding: $popover-header-padding-y $popover-header-padding-x 0.25rem;
	border: 0;
	@include font-size($font-size-lg);
}
.popover-body {
	padding: 0 $popover-body-padding-x $popover-body-padding-y;
}